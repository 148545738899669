import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Card from "../components/card";
import background from "../assets/images/backpat.png";
import image from "../assets/images/GQFRr.gif";
import errorImage from "../assets/images/error-img.gif";
import { useQueryParam, StringParam } from "use-query-params";
import axios from "axios";
import Loading from "../assets/images/loading.gif";

export default function Sent() {
  const [id, setId] = useQueryParam("id", StringParam);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaid, setIsPaid] = useState(true);
  const [order, setOrder] = useState({
    orderName: "",
    orderId: "",
  });

  useEffect(() => {
    var config = {
      method: "get",
      url: "https://secure.vertex-colon.ca/api/orders/validate/" + id,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setIsLoading(false);
        if(response.data.status == 'success'){
          setOrder({
            orderId: response.data.order_id,
            orderName: response.data.name
          });
         
            setIsPaid(true);
        }else{
          setIsLoading(false);
            setIsPaid(false);
        }
      
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsPaid(false);
        console.log(error);
      });
  },[]);
  return (
    <Layout pageTitle="فيرتكس">
      <div style={styles.body}>
        {!isLoading ? (
          isPaid ? (
            <Card blured={true}>
              <h2 style={styles.subTitle}>شكراً لكم</h2>
              <h1 style={styles.title}>لقد تم ارسال طلبكم بنجاح</h1>
              <span style={styles.description}>
                سيقوم احد الموظفين بالتواصل معكم فور تجهيز الطلب
              </span>
              <div style={styles.orderNumber}>
               {order.orderName} رقم طلبك هو {order.orderId}
              </div>

              <img style={styles.img} src={image} alt="del" />

              <a
                style={styles.btn}
                href={`https://api.whatsapp.com/send/?phone=9647823477983&text=مرحباً اريد الاستفسار عن طلبي ذو الرقم ${order.orderId}`}
                target="_blank"
              >
                للاستفسار عن الطلب تواصل معنا
              </a>
            </Card>
          ) : (
            <Card blured={true}>
              <h2 style={styles.subTitle}>نعتذر!</h2>
              <h1 style={styles.title}>لم يتم اتمام الطلب</h1>
              <span style={styles.description}>
                يرجى اعادة محاولة الدفع من جديد او تواصل معنا لمساعدتك
              </span>

              <img style={styles.img} src={errorImage} alt="del" />
              <br></br>

              <a
                style={styles.btn}
                href={`https://api.whatsapp.com/send/?phone=9647823477983&text=مرحباً اريد الاستفسار عن طلبي ذو الرقم ${order.orderId}`}
                target="_blank"
              >
                للاستفسار عن الطلب تواصل معنا
              </a>
            </Card>
          )
        ) : (
          <Card>
            <img style={styles.img} src={Loading} alt="loading" />
          </Card>
        )}
      </div>
    </Layout>
  );
}

const styles = {
  body: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: "auto",
    fontFamily: "bahij",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 20px",
    textAlign: "center",
  },
  title: {
    marginTop: "2px",
    fontSize: "24px",
  },
  subTitle: {
    marginBottom: "0px",
    fontWeight: "normal",
    fontSize: "20px",
  },
  description: {
    marginBottom: "30px",
  },
  btn: {
    textAlign: "center",
    border: "2px solid rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    padding: "10px 15px",
    borderRadius: "10px",
    textDecoration: "none",
    color: "#000",
  },
  img: {
    width: "100%",
  },
  orderNumber:{
    fontSize: "20px",
    padding: "10px 15px",
    backgroundColor: "#fff",
    borderRadius: "10px",
  }
};
